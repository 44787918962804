import moment from 'moment'

const LessonCompletingCalc = ({ lessonDoneReport, expectedLessonDone }, instance) => {
  const group = instance.groupByKey(lessonDoneReport, 'user_id')
  const totalExpectedLessonDone = Object.values(expectedLessonDone).reduce((a, b) => a + b, 0)

  const totalScore = []
  const categories = []
  const summary = []
  Object.entries(group).forEach(([, v]) => {
    categories.push(v[0].user.firstname)
    const score = v.reduce((p, c) => p + Number(c.total_lesson_done), 0)
    const percent = totalExpectedLessonDone > 0 ? (score / totalExpectedLessonDone) * 100 : 0
    totalScore.push(percent.toFixed(2))
    summary.push({
      id: v[0].user.id,
      percent,
    })
  })

  return {
    totalScore,
    summary,
    categories,
  }
}

const ClassRoomSkillReport = ({ skillReport }) => {
  const groupReport = []
  // const skills = [
  //   {
  //     name: 'reading',
  //   },
  //   {
  //     name: 'writing',
  //   },
  //   {
  //     name: 'listening',
  //   },
  //   {
  //     name: 'speaking',
  //   },
  // ]

  skillReport.forEach(item => {
    const report = {}
    item.skill_type.forEach(skill => {
      report[skill.skill_type] = {
        cefr_rate: skill.cefr_rate,
        level: skill.lang_level,
      }
    })
    groupReport.push({
      id: item.user_id,
      fullName: item.student_name,
      data: report,
      averageLevel: item.avg_lang_level,
    })
  })
  // const studentGroups = instance.groupByKey(skillReport, 'user_id')
  // Object.entries(studentGroups).forEach(([, value]) => {
  //   const report = {}
  //   skills.forEach(skill => {
  //     report[skill.name] = value.find(val => val.skill_type === skill.name)
  //   })
  //   groupReport.push({
  //     id: value[0]?.user_id || value[0]?.id,
  //     fullName: `${value[0]?.firstname} ${value[0]?.lastname}`,
  //     data: report,
  //   })
  // })
  return groupReport
}

const AttendanceReportCalc = ({ attendance, totalExpectedDays, maxAttendanceByDate }, instance) => {
  // if teacher schedule is empty but student have attendance
  if (totalExpectedDays === 0) {
    // eslint-disable-next-line no-param-reassign
    totalExpectedDays = Object.entries(maxAttendanceByDate).reduce((a, b) => a + b, 0)
  }

  const group = instance.groupByKey(attendance, 'user_id')

  const totalScore = []
  const categories = []
  const summary = []
  Object.entries(group).forEach(([, v]) => {
    categories.push(v[0].full_name)
    const score = v.reduce((p, c) => p + Number(c.total_attendance), 0)

    let percent = score
    if (totalExpectedDays > 0) {
      percent = ((score / totalExpectedDays) * 100).toFixed(2)
    }
    summary.push({
      id: v[0].user_id,
      percent,
    })
    totalScore.push(percent)
  })

  return {
    summary,
    totalExpectedDays,
    totalScore,
    categories,
  }
}

const NoteReportCalc = ({ noteReportData }, instance) => {
  const noteReport = noteReportData.map(report => ({
    day: moment(report.created_at).format('YYYY-MM-DD'),
    fullName: `${report.student?.firstname} ${report.student?.lastname}`,
    studentId: report.student_id,
    id: report.id,
  }))
  const studentGropedNoteReport = instance.groupByKey(noteReport, 'studentId')
  const months = []
  noteReport.forEach(note => {
    if (!months.find(month => month.day === note.day)) {
      months.push({
        day: note.day,
        displayName: moment(note.day).format('MMM Do'),
        month: moment(note.day).format('MMM'),
      })
    }
  })
  // getStudentComparative
  const maxScore = Math.max(
    ...Object.values(studentGropedNoteReport).map(o => o.length),
  )
  const categories = []
  const seriesData = []
  const summary = []
  Object.values(studentGropedNoteReport).forEach(report => {
    categories.push(report[0].fullName)
    const score = (report.length / maxScore) * 100
    seriesData.push(score.toFixed(2))
    summary.push({
      id: report[0].studentId,
      percent: score.toFixed(2),
    })
  })
  seriesData.sort((a, b) => b - a)

  return {
    summary,
    noteReport,
    months,
    studentGropedNoteReport,
    seriesData,
    categories,
  }
}

export {
  NoteReportCalc,
  AttendanceReportCalc,
  ClassRoomSkillReport,
  LessonCompletingCalc,
}
